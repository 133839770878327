import commonAuthRouter from "@/router/common-auth-router";

const router = {
    path: '/wms',
    name: 'wms',
    component: () => import('@/views/wms/Index.vue'),
    redirect: '/wms/auth',
    children: [
        {
            path: 'auth',
            name: 'auth',
            component: () => import('@/views/wms/Auth.vue'),
            redirect: '/wms/auth/login',
            children: commonAuthRouter
        },
        {
            path: 'home',
            name: 'home',
            component: () => import('@/views/wms/Home.vue'),
            redirect: '/wms/home/warehouse',
            children: [
                {
                    path: 'warehouse',
                    name: 'warehouse',
                    component: () => import('@/views/wms/pages/Warehouse.vue')
                },
                {
                    path: 'rack/:id',
                    name: 'rack',
                    component: () => import('@/views/wms/pages/Rack.vue')
                },
                {
                    path: 'supplement',
                    name: 'supplement',
                    component: () => import('@/views/wms/pages/Supplement.vue')
                },
                {
                    path: 'supplies',
                    name: 'supplies',
                    component: () => import('@/views/wms/pages/Supplies.vue')
                }
            ]
        },
    ]

}
export default router